<template>
    <div :style="'height:' + innerHeight + 'px;vertical-align: bottom;'">
        <div class="bg"></div>
        <div class="e">
            <div class="card">
                <h1>登录</h1>
                <Form ref="formInline" :model="formInline" :rules="ruleInline" >
                    <FormItem prop="account">
                        <div class="card_item" style="margin-top: 40px;">
                            <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                            <Input type="text" v-model="formInline.account" placeholder="请输入账户"></Input>
                        </div>
                    </FormItem>
                    <FormItem prop="pwd">
                        <div class="card_item">
                            <Icon size="18" type="ios-lock-outline" slot="prepend"></Icon>
                            <Input type="password" v-model="formInline.pwd" placeholder="请输入密码"></Input>
                        </div>
                    </FormItem>
                    <FormItem>
                        <Button class="card_btn" @click="handleSubmit('formInline')">登录</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { login, loginJoin } from "@/api/index";
import { setCookies } from "@/utils/helper";
export default {
    data() {
        return {
            token:'',
            innerHeight: 0,
            formInline: {
                account: "",
                pwd: "",
            },
            ruleInline: {
                account: [
                    {
                        required: true,
                        message: "账户不能为空",
                        trigger: "blur",
                    },
                ],
                pwd: [
                    {
                        required: true,
                        message: "密码不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        this.restHeight();
        // this.$router.push({path:this.$route.path,query:{...this.$route.query,t:'3a11c9cbfa6fee8434b6418661b70fb6'}})
        this.token = this.$route.query.t;
        if(this.$route.query.t){
            this.handleSubmit("formInline")
        }
    },
    created() {
        var _this = this;
        document.onkeydown = function () {
            if (_this.$route.name === "登录") {
                let key = window.event.keyCode;
                if (key === 13) {
                    _this.handleSubmit("formInline");
                }
            }
        };
    },
    methods: {
        /**修改高度 */
        restHeight() {
            this.innerHeight = window.innerHeight - 2;
        },
        handleSubmit(name) {
            let menu = [
                // {
                //     children: [],
                //     icon: "md-speedometer",
                //     id: 1,
                //     is_menu: 1,
                //     pid: 0,
                //     src: "/admin",
                //     title: "概况",
                //     unique_auth: "overview",
                // },
                {
                    children: [
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/shop/shoprun/shoplist",
                                    title: "店铺列表",
                                    unique_auth: "/shop/shoprun/shoplist",
                                },
                                {
                                    id: 2,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/shop/shoprun/process",
                                    title: "员工审核",
                                    unique_auth: "/shop/shoprun/process",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/shop/shoprun",
                            title: "店铺管理",
                            unique_auth: "shoprun",
                        },
                    ],
                    icon: "md-card",
                    id: 2,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/shop",
                    title: "店铺",
                    unique_auth: "shop",
                },
                // {
                //     children: [
                //         {
                //             children: [
                //                 {
                //                     id: 1,
                //                     is_menu: 3,
                //                     pid: 0,
                //                     src: "/admin/goods/cgoods/cdiy",
                //                     title: "DIY页面",
                //                     unique_auth: "/goods/cgoods/cdiy",
                //                 },
                //                 {
                //                     id: 2,
                //                     is_menu: 3,
                //                     pid: 0,
                //                     src: "/admin/goods/cgoods/cgroup",
                //                     title: "特性组",
                //                     unique_auth: "/goods/cgoods/cgroup",
                //                 },
                //                 {
                //                     id: 3,
                //                     is_menu: 3,
                //                     pid: 0,
                //                     src: "/admin/goods/cgoods/cclass",
                //                     title: "分类列表",
                //                     unique_auth: "/goods/cgoods/cclass",
                //                 },
                //                 {
                //                     id: 4,
                //                     is_menu: 3,
                //                     pid: 0,
                //                     src: "/admin/goods/cgoods/cgoodslist",
                //                     title: "商品列表",
                //                     unique_auth: "/goods/cgoods/cgoodslist",
                //                 },
                //             ],
                //             id: 1,
                //             is_menu: 2,
                //             pid: 0,
                //             src: "/admin/goods/cgoods",
                //             title: "C端商品",
                //             unique_auth: "cgoods",
                //         },
                //     ],
                //     icon: "md-pricetags",
                //     id: 3,
                //     is_menu: 1,
                //     pid: 0,
                //     src: "/admin/goods",
                //     title: "商品",
                //     unique_auth: "goods",
                // },
                {
                    children: [
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/activity/activitycenter/activitylist",
                                    title: "活动列表",
                                    unique_auth: "/activity/activitycenter",
                                },
                                {
                                    id: 2,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/activity/activitycenter/activitystyle",
                                    title: "活动样式",
                                    unique_auth: "/activity/activitycenter/activitystyle",
                                },
                                {
                                    id: 3,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/activity/activitycenter/bigScreen",
                                    title: "爆破大屏",
                                    unique_auth: "/activity/activitycenter/bigScreen",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/activity/activitycenter",
                            title: "活动中心",
                            unique_auth: "activitycenter",
                        },
                    ],
                    icon: "md-flag",
                    id: 4,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/activity",
                    title: "活动",
                    unique_auth: "activity",
                },
                {
                    children: [
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveFront/livefrontlist",
                                    title: "直播列表",
                                    unique_auth: "/live/liveFront/livefrontlist",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/live/liveFront",
                            title: "直播前",
                            unique_auth: "/live/liveFront",
                        },
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveIn/liveinimg",
                                    title: "直播图片",
                                    unique_auth: "/live/liveIn/liveinimg",
                                },
                                {
                                    id: 2,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveIn/liveindraw",
                                    title: "直播抽奖",
                                    unique_auth: "/live/liveIn/liveindraw",
                                },
                                {
                                    id: 3,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveIn/liveinbag",
                                    title: "直播福袋",
                                    unique_auth: "/live/liveIn/liveinbag",
                                },
                                {
                                    id: 4,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveIn/liveinqa",
                                    title: "直播问答",
                                    unique_auth: "/live/liveIn/liveinqa",
                                },
                                {
                                    id: 5,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveIn/liveingift",
                                    title: "直播送礼",
                                    unique_auth: "/live/liveIn/liveingift",
                                },
                                {
                                    id: 6,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveIn/liveinplugflow",
                                    title: "直播推流",
                                    unique_auth: "/live/liveIn/liveinplugflow",
                                },
                                // {
                                //     id: 7,
                                //     is_menu: 3,
                                //     pid: 0,
                                //     src: "/admin/live/liveIn/liveindraw/setup",
                                //     title: "直播抽奖",
                                //     unique_auth: "/live/liveIn/liveindraw/setup",
                                // },
                                // {
                                //     id: 8,
                                //     is_menu: 3,
                                //     pid: 0,
                                //     src: "/admin/live/liveIn/liveindraw/editSetup",
                                //     title: "直播抽奖",
                                //     unique_auth: "/live/liveIn/liveindraw/editSetup",
                                // },
                                // {
                                //     id: 9,
                                //     is_menu: 3,
                                //     pid: 0,
                                //     src: "/admin/live/liveIn/liveinbag/setUp",
                                //     title: "直播福袋",
                                //     unique_auth: "/live/liveIn/liveinbag/setUp",
                                // },
                            ],
                            id: 2,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/live/liveIn",
                            title: "直播中",
                            unique_auth: "/live/liveIn",
                        },
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/live/liveAfter/liveafterplayback",
                                    title: "直播回放",
                                    unique_auth: "/live/liveAfter/liveafterplayback",
                                },
                            ],
                            id: 3,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/live/liveAfter",
                            title: "直播后",
                            unique_auth: "/live/liveAfter",
                        },
                    ],
                    icon: "md-videocam",
                    id: 5,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/live",
                    title: "直播",
                    unique_auth: "/admin/live",
                },
                {
                    children: [
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/order/query/orderlist",
                                    title: "订单列表",
                                    unique_auth: "/order/query/orderlist",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/order/query",
                            title: "交易查询",
                            unique_auth: "query",
                        },
                    ],
                    icon: "md-list-box",
                    id: 6,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/order",
                    title: "订单",
                    unique_auth: "order",
                },
                {
                    children: [
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/custom/customrun/customlist",
                                    title: "用户列表",
                                    unique_auth: "/custom/customrun/customlist",
                                },
                                {
                                    id: 2,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/custom/customrun/customWithdrawal",
                                    title: "提现审核",
                                    unique_auth: "/custom/customrun/customWithdrawal",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/custom/customrun",
                            title: "客户管理",
                            unique_auth: "customrun",
                        },
                    ],
                    icon: "md-person",
                    id: 7,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/custom",
                    title: "客户",
                    unique_auth: "custom",
                },
                // {
                //     children: [],
                //     icon: "md-paper",
                //     id: 8,
                //     is_menu: 1,
                //     pid: 0,
                //     src: "/admin/finance",
                //     title: "财务",
                //     unique_auth: "finance",
                // },
                {
                    children: [
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/basissetup/numbersetup",
                                    title: "账号设置",
                                    unique_auth: "/setup/basissetup/numbersetup",
                                },
                                {
                                    id: 2,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/basissetup/changePassword",
                                    title: "修改密码",
                                    unique_auth: "/setup/basissetup/changePassword",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/setup/basissetup",
                            title: "基础设置",
                            unique_auth: "/setup/basissetup",
                        },
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/systemsetup/shopsetup",
                                    title: "店铺设置",
                                    unique_auth: "/setup/systemsetup/shopsetup",
                                },
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/systemsetup/paysetup",
                                    title: "支付设置",
                                    unique_auth: "/setup/systemsetup/paysetup",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/setup/systemsetup",
                            title: "系统设置",
                            unique_auth: "/setup/systemsetup",
                        },
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/wananchi/wananchiusher",
                                    title: "公众号引导",
                                    unique_auth: "/setup/wananchi/wananchiusher",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/setup/wananchi",
                            title: "微信公众号",
                            unique_auth: "/setup/wananchi",
                        },
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/applet/appletusher",
                                    title: "小程序引导",
                                    unique_auth: "/setup/applet/appletusher",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/setup/applet",
                            title: "微信小程序",
                            unique_auth: "/setup/applet",
                        },
                        {
                            children: [
                                {
                                    id: 1,
                                    is_menu: 3,
                                    pid: 0,
                                    src: "/admin/setup/video/videousher",
                                    title: "视频号引导",
                                    unique_auth: "/setup/video/videousher",
                                },
                            ],
                            id: 1,
                            is_menu: 2,
                            pid: 0,
                            src: "/admin/setup/video",
                            title: "微信视频号",
                            unique_auth: "/setup/video",
                        },
                    ],
                    icon: "md-settings",
                    id: 9,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/setup",
                    title: "设置",
                    unique_auth: "setup",
                },
            ]
            if(this.token){
                loginJoin({token: this.token}).then(res => {
                    setCookies('meiye-admin-api', res.data.token)
                    setCookies('account', res.data.account)
                    setCookies('name', res.data.name)
                    setCookies('appUrl', res.data.app_url)
                    this.$store.commit("setMenus", menu);
                    this.$Message.success(res.msg);
                    this.$router.push({ path: "/admin/shop/shoprun/shoplist" });
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            }
            if(!this.token){
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        login(this.formInline).then((res) => {
                                setCookies('meiye-admin-api', res.data.token)
                                setCookies('account', res.data.account)
                                setCookies('name', res.data.name)
                                setCookies('appUrl', res.data.app_url)
                                this.$store.commit("setMenus", menu);
                                this.$Message.success(res.msg);
                                this.$router.push({ path: "/admin/shop/shoprun/shoplist" });
                            })
                            .catch((res) => {
                                this.$Message.error(res.msg);
                            });
                    } else {
                        this.$Message.error("数据不能为空");
                    }
                });
            }
        },
    },
};
</script>
<style scoped>
.card{
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 350px;
    padding: 40px;
    box-shadow: -15px 15px 15px rgba(6,17,47,.7);
    opacity: 1;
    border-radius: 12px;
    background: linear-gradient(230deg,rgba(53,57,74,0),#000);
    color: #fff;
}
.card_item{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.card_btn{
    margin: 20px 0;
    background-color: transparent;
    color: #39f;
    border-radius: 20px;
    padding: 10px 20px;
    height: 35px;
    line-height: 15px;
    border: 1px solid #39f;
    width: 100%;
}
.ivu-icon {
    width: 30px;
}
.e {
    margin-top: -20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
}
.bg {
    background-image: url("../../assets/bg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
</style>
